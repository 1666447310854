import React from "react";

interface IBBSProductNameCellProps {
  name: string;
  description: string;
}

const BBSProductNameCell: React.FC<IBBSProductNameCellProps> = ({ name, description }) => {
  return (
    <td>
      <div>{name}</div>
      <div style={{ fontWeight: "lighter", fontSize: "10px" }}>{description}</div>
    </td>
  );
};

export default BBSProductNameCell;
