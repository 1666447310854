import { CancelTokenSource } from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApiError } from "../errors/ApiError";
import ActionMessageType from "../models/ActionMessageType";
import IAccount from "../models/IAccount";
import ILoggedUser from "../models/ILoggedUser";
import ISnackBarMessage from "../models/ISnackBarMessage";
import MspType from "../models/MspType";
import { IGeneralState } from "../reducers/generalReducer";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { TokenStorage } from "../TokenStorage";
import { accountIsBillingAggregator, accountIsCustomer } from "../Utilities/accountsHelper";
import { dynamicSort, matchApiToEcho } from "../utility";
import { AccountActionTypes } from "./accountActions";
import { handleError } from "./actionsErrorHandler";
import CustomerMessagesType from "../models/CustomerMessagesType";
import { getApiUrlIndexRecursive } from "../Utilities/configHelper";
import { localStorageService } from "../service/localStorageService";
import { FinanceActionsTypes } from "./financeActions";
import { ProductActionTypes, getOrdersForAccountsFiltering } from "./productActions";
import { IntegrationsAccountsActionTypes } from "./integrations/integrationsAccountsActions";
import { UserActionTypes } from "./userActions";
import { AuditUsersActionsTypes } from "./auditUsersActions";
import { IntegrationsBillingMapActionTypes } from "./integrations/integrationsBillingActions";
import { IntegrationsLogsActionTypes } from "./integrations/integrationsLogsActions";

export enum GeneralActionTypes {
  GET_API_ECHO_URL = "GET_API_ECHO_URL",
  GENERATE_CUSTOM_ACCESS_TOKEN = "GENERATE_CUSTOM_ACCESS_TOKEN",
  REFRESH_CUSTOM_ACCESS_TOKEN = "REFRESH_CUSTOM_ACCESS_TOKEN",
  LOAD_LOGGEDIN_MSP = "LOAD_LOGGEDIN_MSP",
  SET_SELECTED_TAB = "SET_SELECTED_TAB",
  LOAD_ACTION = "LOAD_ACTION",
  SET_NO_OF_LOADED_SUBPARTNERS = "SET_NO_OF_LOADED_SUBPARTNERS",
  SET_NO_OF_SUBPARTNERS = "SET_NO_OF_SUBPARTNERS",
  ERROR = "ERROR",
  ERROR_AFTER_LOGIN = "ERROR_AFTER_LOGIN",
  SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE",
  LOAD_LOGGED_USER = "LOAD_LOGGED_USER",
  SET_HAS_SUBPARTNERS = "SET_HAS_SUBPARTNERS",
  SET_CUSTOMER_SEARCH_RESULTS = "SET_CUSTOMER_SEARCH_RESULTS",
  SET_VIEW_BILLING_EXCLUSIONS_LIST = "SET_VIEW_BILLING_EXCLUSIONS_LIST",
  SET_CANCEL_TOKEN = "SET_CANCEL_TOKEN",
  GET_HAS_USERS = "GET_HAS_USERS",
  SET_VIEW_INTEGRATIONS = "SET_VIEW_INTEGRATIONS",
  SET_CUSTOMER_FILTER_RESULTS = "SET_CUSTOMER_FILTER_RESULTS",
  SET_SHOW_PARTNER_RESOURCES = "SET_SHOW_PARTNER_RESOURCES",
  SET_SHOW_CUSTOM_MESSAGE_PAGE = "SET_SHOW_CUSTOM_MESSAGE_PAGE",
}

export interface IGetApiEchoUrlAction {
  type: GeneralActionTypes.GET_API_ECHO_URL;
  apiUrl: string;
  echoUrl: string;
}

export interface IGenerateCustomAccessTokenAction {
  type: GeneralActionTypes.GENERATE_CUSTOM_ACCESS_TOKEN;
  accessToken: string;
}

export interface IrefreshEchoV3AccessTokenAction {
  type: GeneralActionTypes.REFRESH_CUSTOM_ACCESS_TOKEN;
  accessToken: string;
}

export interface ILoadLoggedInMsp {
  type: GeneralActionTypes.LOAD_LOGGEDIN_MSP;
  mspAccountLoggedIn: IAccount;
  isMspLoggedIn: boolean;
  hasSubpartners: boolean;
  isBaLoggedIn: boolean;
}

export interface ISetSelectedTabAction {
  type: GeneralActionTypes.SET_SELECTED_TAB;
  selectedTabName: string;
}

export interface ILoadLoggedInUser {
  type: GeneralActionTypes.LOAD_LOGGED_USER;
  loggedUser: ILoggedUser;
  isDefaultUser: boolean | undefined;
}

export interface ILoadAction {
  type: GeneralActionTypes.LOAD_ACTION;
  loading: boolean;
}

export interface ISetNoOfLoadedSubpartners {
  type: GeneralActionTypes.SET_NO_OF_LOADED_SUBPARTNERS;
  noOfLoadedSubpartners: number;
}

export interface IErrorAction {
  type: GeneralActionTypes.ERROR;
  errorMessage: string;
}

export interface IErrorAfterLoginAction {
  type: GeneralActionTypes.ERROR_AFTER_LOGIN;
  apiError: ApiError;
}

export interface ISetSnackBarMessageAction {
  type: GeneralActionTypes.SET_SNACKBAR_MESSAGE;
  snackBarMessage: ISnackBarMessage;
}

export interface ISetHasSubpartnersAction {
  type: GeneralActionTypes.SET_HAS_SUBPARTNERS;
  hasSubpartners: boolean;
}

export interface ISetCancelTokenAction {
  type: GeneralActionTypes.SET_CANCEL_TOKEN;
  cancellationTokenSource: CancelTokenSource;
}

export interface IHasUsersAction {
  type: GeneralActionTypes.GET_HAS_USERS;
  hasUsers: boolean;
}

export interface ISetViewCustomerFilterResultsAction {
  type: GeneralActionTypes.SET_CUSTOMER_FILTER_RESULTS;
  viewFilterResults: boolean;
}

export interface ISetShowCustomMessagePageAction {
  type: GeneralActionTypes.SET_SHOW_CUSTOM_MESSAGE_PAGE;
  showCustomerMessage: boolean;
  customerMessageType: CustomerMessagesType | undefined;
}

export type GeneralActions = IGetApiEchoUrlAction | ILoadLoggedInMsp | ISetSelectedTabAction | ILoadAction | ISetNoOfLoadedSubpartners | IErrorAction | IErrorAfterLoginAction | ISetSnackBarMessageAction | ILoadLoggedInUser | ISetHasSubpartnersAction | ISetCancelTokenAction | IHasUsersAction | ISetViewCustomerFilterResultsAction | ISetShowCustomMessagePageAction;

export const setError: ActionCreator<ThunkAction<any, IGeneralState, null, IErrorAction>> = (errorMessage: string) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.ERROR, errorMessage });

export const setErrorAfterLogin: ActionCreator<ThunkAction<any, IGeneralState, null, IErrorAfterLoginAction>> = (apiError: ApiError) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.ERROR_AFTER_LOGIN, apiError });

export const setSnackBarMessage: ActionCreator<ThunkAction<any, IGeneralState, null, ISetSnackBarMessageAction>> = (snackBarMessage: ISnackBarMessage) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage });

export const removeSnackBarMessage: ActionCreator<ThunkAction<any, IGeneralState, null, ISetSnackBarMessageAction>> = () => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage: { message: "", type: ActionMessageType.Info } });

export const getApiUrlAction: ActionCreator<ThunkAction<Promise<any>, IAppState, IGeneralState, IGetApiEchoUrlAction>> = (urls: any) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const allUrls = JSON.parse(urls);
      const possibleApis = allUrls.map((item: any) => item.api_url);
      const foundUrlIndex = await getApiUrlIndexRecursive(possibleApis);
      switch (foundUrlIndex) {
        case -1: {
          dispatch({
            type: GeneralActionTypes.SET_SHOW_CUSTOM_MESSAGE_PAGE,
            showCustomerMessage: true,
            customerMessageType: CustomerMessagesType.Marketing,
          });
          break;
        }
        case -2: {
          dispatch({
            type: GeneralActionTypes.SET_SHOW_CUSTOM_MESSAGE_PAGE,
            showCustomerMessage: true,
            customerMessageType: CustomerMessagesType.TemporaryUnreachable,
          });
          break;
        }
        default: {
          if (foundUrlIndex !== undefined && foundUrlIndex > -1) {
            dispatch({
              type: GeneralActionTypes.GET_API_ECHO_URL,
              apiUrl: possibleApis[foundUrlIndex],
              echoUrl: matchApiToEcho(possibleApis[foundUrlIndex], allUrls),
            });
            TokenStorage.setApiUrl(possibleApis[foundUrlIndex]);
          }
          break;
        }
      }
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
      return false;
    }
  };
};

export const generateCustomAccessTokenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, IGeneralState, IGenerateCustomAccessTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const token = TokenStorage.getBccIdToken();
      if (token) {
        const response = await mspService.generateCustomAccessToken(apiUrl, token);
        TokenStorage.storeEchoV3AccessToken(response.accessToken);
        TokenStorage.storeEchoV3RefreshToken(response.refreshToken);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
    }
  };
};

export const preFetch = () => {
  return mspService.preFetch(TokenStorage.getApiUrl());
};

export const setSelectedTabName: ActionCreator<ThunkAction<any, IGeneralState, null, ISetSelectedTabAction>> = (selectedTabName: string) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_SELECTED_TAB, selectedTabName });

export const setLoggedUser: ActionCreator<ThunkAction<Promise<any>, IAppState, IGeneralState, ILoadLoggedInUser>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const loggedUser = await mspService.loadLoggedUser(apiUrl);
      dispatch({
        type: GeneralActionTypes.LOAD_LOGGED_USER,
        loggedUser: loggedUser,
        isDefaultUser: loggedUser.email.endsWith("@barracudamsp"),
      });
      TokenStorage.setUserId(loggedUser.id);

      const { accountsPageSize, setupBillingMapTablePageSize, invoicesTableState, essTableState, seTableState, bbsTableState, csTableState, integrationAccountsTableState, tableState, auditUsersTableState, csBillingMapTableState, essBillingMapTableState, ibuBillingMapTableState, logsTableState, serialTableState, accountsWithExclusionsTableState, entitlementsTableState, deleteAccountSerialTableState } = localStorageService.getAll(loggedUser.id);
      dispatch({ type: AccountActionTypes.SET_PAGE_SIZE, accountsPageSize });
      dispatch({ type: IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_SIZE, setupBillingMapTablePageSize });
      dispatch({ type: FinanceActionsTypes.SET_INVOICES_TABLE_STATE, invoicesTableState });
      dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_ESS, essTableState });
      dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_SE, seTableState });
      dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_BBS, bbsTableState });
      dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_CS, csTableState });
      dispatch({ type: UserActionTypes.SET_TABLE_PROPS, tableState });
      dispatch({ type: AuditUsersActionsTypes.GET_AUDIT_USERS_TABLE_PROPS, tableState: auditUsersTableState });
      dispatch({ type: IntegrationsAccountsActionTypes.SET_INTEGRATION_ACCOUNTS_TABLE_PROPS, integrationAccountsTableState });
      dispatch({ type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_IBU, ibuBillingMapTableState });
      dispatch({ type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_ESS, essBillingMapTableState });
      dispatch({ type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_CS, csBillingMapTableState });
      dispatch({ type: IntegrationsLogsActionTypes.SET_LOGS_TABLE_PROPS, logsTableState });
      dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_SERIAL, serialTableState });
      dispatch({ type: FinanceActionsTypes.SET_ACCOUNTS_WITH_EXCLUSIONS_TABLE_PROPS, accountsWithExclusionsTableState });
      dispatch({ type: UserActionTypes.SET_ENTITLEMENTS_TABLE_PROPS, entitlementsTableState });
      dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_DELETE_ACCOUNTS_SERIAL, deleteAccountSerialTableState });
      return loggedUser.email.endsWith("@barracudamsp");
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
    }
  };
};

export const loadLoggedInMsp: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ILoadLoggedInMsp>> = (isDefaultUser: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({ type: GeneralActionTypes.LOAD_ACTION, loading: true });
      const { newMspAccountLoggedIn, subpartners } = await mspService.loadPartner(apiUrl);
      if (accountIsBillingAggregator(newMspAccountLoggedIn)) {
        dispatch({
          type: AccountActionTypes.SET_MSP_ACCOUNTS,
          mspAccounts: newMspAccountLoggedIn.accounts,
          itemsToDisplay: [newMspAccountLoggedIn, ...newMspAccountLoggedIn.accounts],
        });
        dispatch({
          mspAccountLoggedIn: newMspAccountLoggedIn,
          type: GeneralActionTypes.LOAD_LOGGEDIN_MSP,
          isMspLoggedIn: true,
          hasSubpartners: false,
          isBaLoggedIn: true,
        });
      } else if (accountIsCustomer(newMspAccountLoggedIn)) {
        const parent = await mspService.loadParentAccount(apiUrl, newMspAccountLoggedIn.id, undefined);
        newMspAccountLoggedIn.closestParentId = parent[0].id;
        dispatch({
          type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
          loadingAllChildrenAccountNames: false,
          accountsNames: [parent[0], newMspAccountLoggedIn],
        });
        dispatch({
          type: AccountActionTypes.SET_MSP_ACCOUNTS,
          mspAccounts: [parent[0], newMspAccountLoggedIn],
          itemsToDisplay: [newMspAccountLoggedIn],
        });
        dispatch({
          mspAccountLoggedIn: newMspAccountLoggedIn,
          type: GeneralActionTypes.LOAD_LOGGEDIN_MSP,
          isMspLoggedIn: true,
          hasSubpartners: false,
        });
      } else {
        const hasSubpartners = subpartners.length > 0;
        if (!isDefaultUser) {
          let allChildrenNames = [];

          const allChildren = await mspService.fetchAccountAllChildren(apiUrl, newMspAccountLoggedIn, undefined);
          allChildrenNames = allChildren.accounts.filter((x: IAccount) => x.type === MspType.Customer || x.type === MspType.Subpartner || x.type === MspType.Partner);
          dispatch({
            type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
            loadingAllChildrenAccountNames: false,
            accountsNames: allChildrenNames,
          });

          const mspAccounts = updateMspAccountsWithChildren(subpartners, allChildrenNames);

          dispatch({
            type: AccountActionTypes.SET_MSP_ACCOUNTS,
            mspAccounts: [newMspAccountLoggedIn, ...mspAccounts],
            itemsToDisplay: hasSubpartners ? [newMspAccountLoggedIn, ...mspAccounts] : [newMspAccountLoggedIn, ...newMspAccountLoggedIn.accounts],
          });

          if (window.location.search.length > 0) {
            if (window.location.search.includes("accountsOf=")) {
              const accountId = window.location.search.split("accountsOf=")[1].split("&")[0];
              const foundSubpartner = subpartners.find((x: IAccount) => x.id === Number(accountId));
              if (foundSubpartner) {
                await getOrdersForAccountsFiltering(getState, dispatch, foundSubpartner.id);
              }
            }
            await getOrdersForAccountsFiltering(getState, dispatch, newMspAccountLoggedIn.id);
          }
        }
        dispatch({
          mspAccountLoggedIn: newMspAccountLoggedIn,
          type: GeneralActionTypes.LOAD_LOGGEDIN_MSP,
          isMspLoggedIn: true,
          hasSubpartners: hasSubpartners && !isDefaultUser,
        });
      }
      return newMspAccountLoggedIn;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: GeneralActionTypes.LOAD_ACTION,
          loading: false,
        });
      });
      return undefined;
    }
  };
};

export const loadAction: ActionCreator<ThunkAction<any, IGeneralState, null, ILoadAction>> = (shouldLoad: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.LOAD_ACTION, loading: shouldLoad });

export const setNoOfLoadedSubpartners: ActionCreator<ThunkAction<any, IGeneralState, null, ISetNoOfLoadedSubpartners>> = (noOfLoadedSubpartners: number) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_NO_OF_LOADED_SUBPARTNERS, noOfLoadedSubpartners });

export const setHasSubpartnersAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetHasSubpartnersAction>> = (hasSubpartners: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_HAS_SUBPARTNERS, hasSubpartners });

export const setCancelTokenAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetCancelTokenAction>> = (cancellationTokenSource: CancelTokenSource) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_CANCEL_TOKEN, cancellationTokenSource });

export const cancelCurrentAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const cancelTokenSource = getState().generalState.cancellationTokenSource;
    if (cancelTokenSource) {
      cancelTokenSource?.cancel("Operation canceled.");
    }
    dispatch({
      cancellationTokenSource: undefined,
      type: GeneralActionTypes.SET_CANCEL_TOKEN,
    });
  };
};

export const hasUsersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IHasUsersAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const accountUsers = await mspService.loadAccountUsers(apiUrl, account);
      dispatch({
        type: GeneralActionTypes.GET_HAS_USERS,
        hasUsers: accountUsers.length > 0,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* do nothing */
        },
        () => {
          /* do nothing */
        },
      );
      return false;
    }
  };
};

export const setShowCustomMessagePageAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetShowCustomMessagePageAction>> = (showCustomerMessage: boolean, customerMessageType: CustomerMessagesType) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: GeneralActionTypes.SET_SHOW_CUSTOM_MESSAGE_PAGE,
      showCustomerMessage: showCustomerMessage,
      customerMessageType: customerMessageType,
    });
  };
};

export function updateMspAccountsWithChildren(subpartners: IAccount[], allChildrenNames: IAccount[]): IAccount[] {
  subpartners.forEach((subpartner: IAccount) => {
    const children = allChildrenNames.filter((x: IAccount) => x.closestParentId === subpartner.id);
    children.sort(dynamicSort("name"));
    subpartner.accounts = children;
  });
  return subpartners;
}
